jQuery(document).ready((a) => {
  a(".wp-block-image").each((e, s) => {
    a(s).find(".wp-element-caption").length > 0 &&
      a(s).addClass("with-caption");
  }),
    a(".malansac-header-content h2").each((e, s) => {
      a(s).html().includes("Malansac") &&
        (a(s).addClass("signature"),
        (text = a(s).html().replace("Malansac", "<span>Malansac</span>")),
        a(s).html(text));
    }),
    a(document)
      .on("mouseenter", ".malansac-header__menu__nav > .menu-item", (e) => {
        a(".sub-menu").removeClass("show"),
          a(e.target).find(".sub-menu").addClass("show");
      })
      .on("mouseleave", ".malansac-header__menu__nav > .menu-item", (e) => {
        a(e.target).find(".sub-menu").removeClass("show");
      }),
    a(document).click((e) => {
      a(e.target).parents().hasClass("malansac-header__menu__nav") ||
        a(".sub-menu").removeClass("show");
    }),
    a(document).on("click", ".search_ico", () => {
      a(".malansac-header__search__form").toggleClass("open");
    }),
    a(document).on("click", ".burger", () => {
      a(".malansac-header__menu__nav").parent().toggleClass("open");
    }),
    a(document).on("click", ".malansac-arrow", () => {
      a("html, body").animate(
        { scrollTop: a(".malansac-arrow").offset().top },
        "slow"
      );
    }),
    a(document).on("click", ".home .actu-filtre", (e) => {
      const s = a(e.target).data("cat");
      actuSlide && actuSlide.slick("slickUnfilter"),
        "all" === s
          ? a(".actu").addClass("show")
          : (a(".actu").removeClass("show"),
            a('.tag-cat[data-cat="' + s + '"]')
              .parent()
              .parent()
              .addClass("show"));
    });
});
const malansacActus = document.querySelector(".malansac-actus");
if (malansacActus) {
  new Swiper(malansacActus, {
    slidesPerView: 3,
    spaceBetween: 30,
    loop: !0,
    navigation: {
      nextEl: ".malansac-actu-content .next",
      prevEl: ".malansac-actu-content .prev",
    },
    breakpoints: {
      1200: { slidesPerView: 3 },
      768: { slidesPerView: 2 },
      1: { slidesPerView: 1 },
    },
  });
}
